import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://f3b9f2cf967d7bab0ec44556fc0c5f62@o167326.ingest.us.sentry.io/4507037939073024",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0,
  integrations: [],
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 0,
});